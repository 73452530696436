import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { Message } from 'element-ui'

Vue.use(VueRouter)


// 路由规则(添加需要认证的 requiresAuth 信息)
const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: 'login' */'@/views/login/index')
  },
  {
    path: '/phone/pack',
    name: 'phonePack',
    component: () => import(/* webpackChunkName: 'phonePack' */'@/views/phone/pack/index')
  },
  {
    path: '/phone/pack',
    name: 'phonePack',
    component: () => import(/* webpackChunkName: 'phonePack' */'@/views/phone/pack/index')
  },
  {
    path: '/phone/entranceSigned',
    name: 'phoneEntranceSigned',
    component: () => import(/* webpackChunkName: 'phoneBack' */'@/views/phone/entranceSigned/index')
  },
  {
    path: '/phone/putGoods',
    name: 'phonePutGoods',
    component: () => import(/* webpackChunkName: 'phonePutGoods' */'@/views/phone/putgoods/index')
  },
  {
    path: '/phone/panKuGoods',
    name: 'phonePanKuGoods',
    component: () => import(/* webpackChunkName: 'phonePanKuGoods' */'@/views/phone/pankugoods/index')
  },
  {
    path: '/phone/packing',
    name: 'phonePacking',
    component: () => import(/* webpackChunkName: 'phonePacking' */'@/views/phone/packing/index')
  },
  {
    path: '/phone/orderPacking',
    name: 'phoneOrderPacking',
    component: () => import(/* webpackChunkName: 'phoneOrderPacking' */'@/views/phone/orderPacking/index')
  },
  {
    path: '/phone/orderLoading',
    name: 'phoneOrderLoading',
    component: () => import(/* webpackChunkName: 'phoneOrderLoading' */'@/views/phone/orderLoading/index')
  },
  {
    path: '/phone/packingBack',
    name: 'phonePackingBack',
    component: () => import(/* webpackChunkName: 'phonePackingBack' */'@/views/phone/packingback/index')
  },{
    path: '/phone/packed',
    name: '',
    component: () => import(/* webpackChunkName: 'phonePacked' */'@/views/phone/packed/index')
  },
  {
    path: '/phone/panel',
    name: 'phonePanel',
    component: () => import(/* webpackChunkName: 'phonePanel' */'@/views/phone/panel/index'),
    meta: {
      requireAuth: true,
      keepAlive: false // 需要被缓存
    },
  },
  {
    path: '/phone/identify',
    name: 'phoneIdentify',
    component: () => import(/* webpackChunkName: 'phoneIdentify' */'@/views/phone/identify/index'),
    meta: {
      requireAuth: true,
      keepAlive: false // 需要被缓存
    },
  },
  {
    path: '/phone/identifyGoods',
    name: 'phoneIdentifyGoods',
    component: () => import(/* webpackChunkName: 'phoneIdentifyGoods' */'@/views/phone/identifyGoods/index'),
    meta: {
      requireAuth: true,
      keepAlive: false // 需要被缓存
    },
  },
  {
    path: '/phone/scanning',
    name: 'phoneScanning',
    component: () => import(/* webpackChunkName: 'phoneScanning' */'@/views/phone/scanning/index')
  },
  {
    path: '/phone/scanningBack',
    name: 'phoneScanningBack',
    component: () => import(/* webpackChunkName: 'phoneScanningBack' */'@/views/phone/scanningback/index')
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: 'layout' */'@/views/layout/index'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import(/* webpackChunkName: 'home' */'@/views/home/index'),
        meta: {
          requireAuth: true,
          keepAlive: false // 需要被缓存
        },
      },
      {
        path: '/panel',
        name: 'panel',
        component: () => import(/* webpackChunkName: 'panel' */'@/views/panel/index'),
        meta: {
          requireAuth: true,
          keepAlive: false // 需要被缓存
        },
      },
      {
        path: '/down',
        name: 'down',
        component: () => import(/* webpackChunkName: 'down' */'@/views/down/index'),
        meta: {
          requireAuth: true,
          keepAlive: false // 不需要被缓存
        },
      },
      {
        path: '/callback',
        name: 'callback',
        component: () => import(/* webpackChunkName: 'callback' */'@/views/callback/index'),
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },
      {
        path: '/role',
        name: 'role',
        component: () => import(/* webpackChunkName: 'role' */'@/views/role/index'),
        meta: {
          requireAuth: true,
          keepAlive: false // 需要被缓存
        },
      },
      {
        path: '/menu',
        name: 'menu',
        component: () => import(/* webpackChunkName: 'menu' */'@/views/menu/index'),
        meta: {
          requireAuth: true,
          keepAlive: false // 需要被缓存
        },
      },
      {
        path: '/resource',
        name: 'resource',
        component: () => import(/* webpackChunkName: 'resource' */'@/views/resource/index'),
        meta: {
          requireAuth: true,
          keepAlive: false // 需要被缓存
        },
      },
      {
        path: '/user',
        name: 'user',
        component: () => import(/* webpackChunkName: 'user' */'@/views/user/index'),
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },
      // 添加菜单路由组件
      {
        path: '/menu/create',
        name: 'addMenu',
        component: () => import(/* webpackChunkName: 'menu-create' */'@/views/menu/create'),
        meta: {
          requireAuth: true,
          keepAlive: false // 需要被缓存
        },
      },
      // // 编辑菜单路由组件 :id 会根据 params的id自动填充 this.$router.push({name:'menu-edit',params: {id: row.id}})
      {
        path: '/menu/edit/:id',
        name: 'updateMenu',
        component: () => import(/* webpackChunkName: 'menu-edit' */'@/views/menu/edit'),
        meta: {
          requireAuth: true,
          keepAlive: false // 需要被缓存
        },
      },
      // // 分配菜单路由组件
      {
        path: '/role/:roleId/alloc-menu',
        name: 'allocMenu',
        component: () => import(/* webpackChunkName: 'alloc-menu' */'@/views/role/alloc-menu'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: false // 需要被缓存
        },
      },
      {
        path: '/mailNo',
        name: 'mailNo',
        component: () => import(/* webpackChunkName: 'mailNo' */'@/views/logistics/mailNo/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },
      {
        path: '/subscribe',
        name: 'subscribe',
        component: () => import(/* webpackChunkName: 'subscribe' */'@/views/logistics/subscribe/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },{
        path: '/pack',
        name: 'pack',
        component: () => import(/* webpackChunkName: 'pack' */'@/views/logistics/pack/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: false // 需要被缓存
        },
      },{
        path: '/delBatch',
        name: 'delBatch',
        component: () => import(/* webpackChunkName: 'delBatch' */'@/views/logistics/del/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },{
        path: '/updateStatusBatch',
        name: 'updateStatusBatch',
        component: () => import(/* webpackChunkName: 'updateStatusBatch' */'@/views/logistics/updateStatus/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },{
        path: '/print',
        name: 'print',
        component: () => import(/* webpackChunkName: 'print' */'@/views/logistics/print/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: false // 需要被缓存
        },
      },{
        path: '/dept',
        name: 'dept',
        component: () => import(/* webpackChunkName: 'dept' */'@/views/logistics/dept/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: false // 需要被缓存
        },
      },{
        path: '/waylay',
        name: 'waylay',
        component: () => import(/* webpackChunkName: 'waylay' */'@/views/logistics/waylay/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },{
        path: '/unAccept',
        name: 'unAccept',
        component: () => import(/* webpackChunkName: 'waylay' */'@/views/logistics/unaccept/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      }, {
        path: '/notUpdate',
        name: 'notUpdate',
        component: () => import(/* webpackChunkName: 'waylay' */'@/views/logistics/notupdate/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },{
        path: '/badNo',
        name: 'badNo',
        component: () => import(/* webpackChunkName: 'badno' */'@/views/logistics/badno/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },{
        path: '/back',
        name: 'back',
        component: () => import(/* webpackChunkName: 'back' */'@/views/logistics/back/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      }
      ,{
        path: '/agentPanel',
        name: 'agentPanel',
        component: () => import(/* webpackChunkName: 'agentPanel' */'@/views/agent/panel/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },{
        path: '/agentMailNo',
        name: 'agentMailNo',
        component: () => import(/* webpackChunkName: 'agentMailNo' */'@/views/agent/mailNo/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },{
        path: '/agentWaylay',
        name: 'agentWaylay',
        component: () => import(/* webpackChunkName: 'agentWaylay' */'@/views/agent/waylay/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },{
        path: '/agentUnAccept',
        name: 'agentUnAccept',
        component: () => import(/* webpackChunkName: 'agentUnAccept' */'@/views/agent/unaccept/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      }, {
        path: '/agentNotUpdate',
        name: 'agentNotUpdate',
        component: () => import(/* webpackChunkName: 'agentNotUpdate' */'@/views/agent/notupdate/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },
      {
        path: '/agentBadNo',
        name: 'agentBadNo',
        component: () => import(/* webpackChunkName: 'agentBadNo' */'@/views/agent/badno/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },{
        path: '/agentBack',
        name: 'agentBack',
        component: () => import(/* webpackChunkName: 'agentBack' */'@/views/agent/back/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },{
        path: '/apply',
        name: 'apply',
        component: () => import(/* webpackChunkName: 'apply' */'@/views/produce/apply/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },{
        path: '/schedule',
        name: 'schedule',
        component: () => import(/* webpackChunkName: 'schedule' */'@/views/produce/schedule/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },{
        path: '/prodton',
        name: 'prodton',
        component: () => import(/* webpackChunkName: 'prodton' */'@/views/produce/prodton/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },{
        path: '/notice',
        name: 'notice',
        component: () => import(/* webpackChunkName: 'notice' */'@/views/produce/notice/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },{
        path: '/markdecode',
        name: 'markdecode',
        component: () => import(/* webpackChunkName: 'markdecode' */'@/views/data/markdecode/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },{
        path: '/category',
        name: 'category',
        component: () => import(/* webpackChunkName: 'category' */'@/views/product/category/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },{
        path: '/colour',
        name: 'colour',
        component: () => import(/* webpackChunkName: 'colour' */'@/views/product/colour/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },{
        path: '/specs',
        name: 'specs',
        component: () => import(/* webpackChunkName: 'specs' */'@/views/product/specs/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },{
        path: '/standard',
        name: 'standard',
        component: () => import(/* webpackChunkName: 'standard' */'@/views/product/standard/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },{
        path: '/unit',
        name: 'unit',
        component: () => import(/* webpackChunkName: 'unit' */'@/views/product/unit/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },{
        path: '/goods',
        name: 'goods',
        component: () => import(/* webpackChunkName: 'goods' */'@/views/product/goods/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },{
        path: '/goods/print',
        name: 'printGoods',
        component: () => import(/* webpackChunkName: 'printGoods' */'@/views/product/goods/components/print'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: false // 不需要被缓存
        },
      },{
        path: '/inject',
        name: 'inject',
        component: () => import(/* webpackChunkName: 'inject' */'@/views/product/inject/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },{
        path: '/inject/print',
        name: 'printInject',
        component: () => import(/* webpackChunkName: 'printInject' */'@/views/product/inject/components/print'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: false // 不需要被缓存
        },
      },{
        path: '/storeArea',
        name: 'storeArea',
        component: () => import(/* webpackChunkName: 'storeArea' */'@/views/storeArea/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },{
        path: '/putGoods',
        name: 'putGoods',
        component: () => import(/* webpackChunkName: 'putGoods' */'@/views/product/putgoods/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },{
        path: '/panKuGoods',
        name: 'panKuGoods',
        component: () => import(/* webpackChunkName: 'panKuGoods' */'@/views/product/pankugoods/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 需要被缓存
        },
      },{
        path: '/packing',
        name: 'packing',
        component: () => import(/* webpackChunkName: 'packing' */'@/views/logistics/packing/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: false // 不需要被缓存
        },
      },{
        path: '/packingBack',
        name: 'packingBack',
        component: () => import(/* webpackChunkName: 'packingBack' */'@/views/logistics/packingback/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: false // 不需要被缓存
        },
      },{
        path: '/packed',
        name: 'packed',
        component: () => import(/* webpackChunkName: 'packed' */'@/views/logistics/packed/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: false // 不需要被缓存
        },
      },{
        path: '/sendBack',
        name: 'sendBack',
        component: () => import(/* webpackChunkName: 'sendBack' */'@/views/sendback/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: false // 不需要被缓存
        },
      },{
        path: '/order',
        name: 'order',
        component: () => import(/* webpackChunkName: 'order' */'@/views/order/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: false // 不需要被缓存
        },
      },{
        path: '/order/print',
        name: 'printOrder',
        component: () => import(/* webpackChunkName: 'printOrder' */'@/views/order/components/print'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: false // 不需要被缓存
        },
      },{
        path: '/orderPacking',
        name: 'orderPacking',
        component: () => import(/* webpackChunkName: 'orderPacking' */'@/views/order/packing/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: false // 不需要被缓存
        },
      },{
        path: '/orderLoading',
        name: 'orderLoading',
        component: () => import(/* webpackChunkName: 'orderLoading' */'@/views/order/loading/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: false // 不需要被缓存
        },
      },{
        path: '/ane56UploadPre',
        name: 'ane56UploadPre',
        component: () => import(/* webpackChunkName: 'ane56UploadPre' */'@/views/ane56/preupload/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: false // 需要被缓存
        },
      },{
        path: '/ane56mailNo',
        name: 'ane56mailNo',
        component: () => import(/* webpackChunkName: 'ane56mailNo' */'@/views/ane56/mailNo/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: false // 需要被缓存
        },
      },{
        path: '/identify',
        name: 'identify',
        component: () => import(/* webpackChunkName: 'identify' */'@/views/ane56/identify/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: false // 需要被缓存
        },
      },{
        path: '/identifyGoods',
        name: 'identifyGoods',
        component: () => import(/* webpackChunkName: 'identifyGoods' */'@/views/product/identify/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: false // 需要被缓存
        },
      },{
        path: '/scanning',
        name: 'scanning',
        component: () => import(/* webpackChunkName: 'scanning' */'@/views/packback/scanning/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: false // 不需要被缓存
        },
      },{
        path: '/scanningBack',
        name: 'scanningBack',
        component: () => import(/* webpackChunkName: 'scanning' */'@/views/packback/scanningback/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: false // 不需要被缓存
        },
      },{
        path: '/scanningList',
        name: 'scanningList',
        component: () => import(/* webpackChunkName: 'scanningList' */'@/views/packback/scanninglist/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: false // 不需要被缓存
        },
      },{
        path: '/entranceList',
        name: 'entranceList',
        component: () => import(/* webpackChunkName: 'entranceList' */'@/views/packback/entrancelist/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: false // 不需要被缓存
        },
      },{
        path: '/entranceSigned',
        name: 'entranceSigned',
        component: () => import(/* webpackChunkName: 'scanningList' */'@/views/packback/entranceSigned/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: false // 不需要被缓存
        },
      },{
        path: '/waitingPanel',
        name: 'waitingPanel',
        component: () => import(/* webpackChunkName: 'waitingPanel' */'@/views/order/waitingPanel/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: false // 不需要被缓存
        },
      },{
        path: '/msg',
        name: 'msg',
        component: () => import(/* webpackChunkName: 'msg' */'@/views/msg/index'),
        props: true,
        meta: {
          requireAuth: true,
          keepAlive: true // 不需要被缓存
        },
      }
    ]
  },
  {
    path: '*',
    name: 'error-page',
    component: () => import(/* webpackChunkName: 'error-page' */'@/views/error-page/index')
  }
]

const router = new VueRouter({
  routes
})

function checkToPathPermissions(name) {
  debugger
  let menuListArr = []
  let menuList = store.state.menuList
  for (const menuListElement of menuList) {
    if (null !=menuListElement.href && ''!=menuListElement.href) {
      const menuItem = menuListElement.href
      menuListArr.push(menuItem)
    }
    if (null != menuListElement.subMenuList) {
      for (const subMenuListElement of menuListElement.subMenuList) {
        if (null !=subMenuListElement.href && ''!=subMenuListElement.href){
          const menuItem = subMenuListElement.href
          menuListArr.push(menuItem)
        }
      }
    }
  }
  let result = menuListArr.indexOf(name)
  if (result>-1) {
    return true
  } else {
    return false
  }
}

// 路由导航守卫
router.beforeEach(async (to,from,next) =>{
  // 需要进行身份认验证 to 路由是否证
  if (to.matched.some(record => record.meta.requireAuth)){
    await store.commit('setRouteCnName',to.name)
    await store.dispatch('setMenuList')
    // if (!checkToPathPermissions(to.name)) {
    //   Message.error('无权限访问')
    //   store.commit('setUser',null)
    //   // return next('home')
    // }
    // 验证Vuex 的store中的登录用户信息是否存在
    if(!store.state.user) {
      // 未登录，跳转的登录页
      return next({
        name:'login',
        // query: {
        //   //将本次路由的 fullPath 传递给 login页面
        //   redirect: to.fullPath
        // }
      })
    }
    // 已经登录，允许通过
    next()
  } else {
    //当前页面不需要验证
    //确保一定要调用next()
    next()
  }

})

export default router
