import request from '@/utils/request'
import store from '@/store'

// 用户登录接口
export function login(data) {
  // 返回一个axios对象 => promise  // 返回了一个promise对象
  return request({
    url: '/auth/user/login',
    method: 'post',
    data
  })
}

// 获取用户基本信息
export function getUserInfo() {
  // 返回一个axios对象 => promise  // 返回了一个promise对象
  return request({
    url: `/auth/user/getUserInfo?userId=${store.state.user.user_id}`,
    method: 'get'
  })
}

// 用户分页&多条件查询方法
export function getUserPages(data) {
  return request({
    url: '/auth/user/findAllUserByPage',
    method: 'post',
    data
  })
}

// 获取用户基本信息
export function updateUserStatus(id,status) {
  // 返回一个axios对象 => promise  // 返回了一个promise对象
  return request({
    url: `/auth/user/updateUserStatus`,
    method: 'get',
    params:{
      id,
      status
    }
  })
}

// 为用户分配角色信息
export function userContextRole(data) {
  return request({
    method: 'post',
    url: `/auth/user/userContextRole`,
    data
  })
}

// 查询用户角色
export function findUserRoleById(id) {
  // 返回一个axios对象 => promise  // 返回了一个promise对象
  return request({
    url: `/auth/user/findUserRoleById`,
    method: 'get',
    params:{
      id
    }
  })
}

// 查询菜单信息(回显)
export function getUserPermissions() {
  return request({
    method: 'get',
    url: `/auth/user/getUserPermissions`
  })
}
