import Vue from 'vue'
import Vuex from 'vuex'
import {routeNameToCn} from '@/utils/routeTrans'
import {getUserPermissions} from '@/services/user'
import tagsView from "./tagsView";

Vue.use(Vuex)

export default new Vuex.Store({
  // 调用state里面的数据 this.$store.state.数据
  state: {

    // 用于在登录成功后保存用户信息（初始值尝试读取本地存值）
    user: JSON.parse(window.localStorage.getItem('user') || null),
    routeCnName: '',
    menuList: []
  },
  // 调用mutations里面的方法 this.$store.commit('方法名',{传入的数据})
  mutations: {
    //存储用户数据
    setUser (state, payload) {
      state.user = payload
      // 将 payload 数据添加到本地存储中 localStroage存储的是字符串
      window.localStorage.setItem('user', JSON.stringify(payload))
    },
    setRouteCnName(state,payload) {
      state.routeCnName = routeNameToCn(payload)
    },
    setMenuList(state, payload) {
      state.menuList = payload
    }
  },
  // 调用actions里面的方法 this.$store.dispatch('方法名',{传入的数据})
  actions: {
    async setMenuList(context,payload) {
      const {data} =await getUserPermissions()
      if (200===data.code) {
        context.commit('setMenuList',data.data.menuList)
      } else {
        context.commit('setMenuList',[])
      }
    }

  },
  modules: {
    tagsView
  }
})
