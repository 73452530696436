import axios from 'axios'
import store from '@/store'
// 通过局部引入方式，引入 Element 的Message组件功能
import { Message } from 'element-ui'
import {getBaseURL,redirectLogin} from '@/utils/someCommen'

// create 创建 axios 实例 15秒
const request = axios.create({
  timeout: 30*1000
})



// 请求拦截器
request.interceptors.request.use(function (config) {
  // 判断config.url的前缀，来进行请求baseURL 的设置
  config.baseURL = getBaseURL(config.url)
  // 统一设置token信息
  const { user } = store.state
  if (user && user.access_token) {
    config.headers.Authorization = user.access_token
  }
  return config
})


// 存储是否正在更新 Token 的状态
let isRefreshing = false
// 存储因为等待 Token 刷新而挂起的请求
let requests = []

// 响应拦截器
request.interceptors.response.use(function (response) {
  // 状态码 2xx 会执行这里
  const { code } = response.data
  if (code ===-211) {
    redirectLogin ()
    // return Message.error('请重新登录')
  }
  if (code === 401) {
    // 1.无Token信息
    if (!store.state.user) {
      // 跳转登录页
      redirectLogin ()
      return Message.error('无令牌，请重新登录')
    }

    // 检测是否已经存在了正在刷新 token的请求 isRefreshing是全局变量，不同请求会相互影响
    if (isRefreshing) {
      // 将当前失败的请求，存储到请求列表中
      return requests.push(()=>{
        // 当前函数调用后，会自动发送本次因为401失败的请求 这个response是局部变量，不同请求互不影响
        request(response.config)
      })
    }
    isRefreshing = true
    // 2.Token 无效(错误 Token,过期Token)
    // -发送请求，获取新的access_token
    return request({
      method: 'post',
      url:'/home/user/refreshToken',
      data: {
        refreshToken: store.state.user.refresh_token
      }
    }).then(res =>{
      // - 刷新 token 失败
      if (res.data.code !== 200) {
        // 清除无效的用户信息
        store.commit('setUser',null)
        // 跳转登录页
        redirectLogin ()
        return Message.error('令牌失效，请重新登录')
      }
      // 刷新token成功
      // -存储新的token
      store.commit('setUser',res.data.data)
      // -重新发送失败的请求 （根据 requests 发送所有失败的请求）
      requests.forEach(callback => callback())
      // - 发送完毕， 清除 requests 内容即可
      requests = []
      // -将本次请求发送
      return request(response.config)
    }).catch(err=>{
      console.log(err)
      return Message.error('令牌刷新失败')
    }).finally(()=>{
      // 请求发送完毕，响应处理完毕，将刷新状态更改为 false即可
      isRefreshing = false
    })
  } else if (code === 400) {
    //请求参数错误
    return Message.error('请求参数错误')
  } else if (code === 403) {
    //没有权限，请联系管理员
    return Message.error('没有权限，请联系管理员')
  } else if (code === 500) {
    //服务端错误，请联系管理员
    return Message.error('服务端错误，请联系管理员')
  }
  return response
}, function (error) {
  if (error.response) {
    // 请求发送成功，响应接收完毕，但状态码为失败的情况
    // 判断失败的状态码的情况（主要处理 401 的情况）
    const { status } = error.response
    let errorMessage = ''
    if (status === 404) {
      errorMessage = '请求资源不存在'
    } else if (status === 500) {
      errorMessage = '服务端错误，请联系管理员'
    }
    return Message.error(errorMessage)
  } else if (error.request) {
    // 请求发送成功，但是未收到响应
    return Message.error(error.request)
  } else {
    // 意料之外的错误
    return Message.error(error.message)
  }
  // 将本次请求的错误对象继续向后抛出，让接收响应的处理函数进行操作
  // return Message.error(error.message)
})

export default request
