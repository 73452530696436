import router from '../router'


/*
 this.$router.push({
  name:'login',
  query: {
    //将本次路由的 fullPath 传递给 login页面
    redirect: this.$route.fullPath
  }
})
 */
// 跳转登录页
export function redirectLogin () {
  router.push({
    name: 'login',
    // query: {
    //   // currentRoute 就是存储了路由信息的对象，相当于$Route
    //   redirect: router.currentRoute.fullPath
    // }
  })
}

// 设置基本请求URL
export function getBaseURL (url) {
  if (url.startsWith('/auth')) {
    // return 'https://2474m0w400.yicp.fun/'
    // return 'http://pg.xgkezhan.cn/'
    // return 'http://localhost:8086/'
    return 'http://pangrow.top/'

  }
}
