
let routeNameKeyMap = {
  home: '首页',
  role: '角色列表',
  menu: '菜单列表',
  resource: '资源列表',
  course: '课程管理',
  user: '用户管理',
  addMenu: '创建菜单',
  updateMenu: '编辑菜单',
  allocMenu : '分配菜单',
  mailNo: '快递单号查询',
  subscribe: '实时物流订阅',
  pack: '包裹称重',
  print: '上传单号',
  waylay: '快递拦截',
  unAccept: '未揽收查询',
  notUpdate: '物流未更新查询',
  badNo: '异常管理',
  back: '退货管理',
  down: '下载数据',
  schedule: '生产排班',
  prodton: '生产调色',
  notice: '车间领货',
  panel: '信息面板',
  markdecode: '备注解析',
  apply: '生产申请',
  agentPanel: '信息面板',
  agentMailNo: '快递单号查询',
  agentWaylay: '快递拦截',
  agentUnAccept: '未揽收查询',
  agentNotUpdate: '物流未更新查询',
  agentBadNo: '异常管理',
  agentBack: '退货管理',
  delBatch: '单号删除',
  updateStatusBatch: '批量更新状态',
  category: '分类维护',
  colour: '分类颜色',
  specs: '分类规格',
  standard: '执行标准',
  unit: '计量单位',
  goods: '商品维护',
  printGoods: '打印合格证',
  inject: '灌装管理',
  storeArea: '区位维护',
  putGoods: '上架',
  packing: '拣货',
  packed: '打包',
  packingBack: '拣货回退',
  sendBack: '送回楼层',
  order: '订单管理',
  printOrder: '线下订单打印',
  orderPacking: '线下订单拣货',
  orderLoading: '线下装车',
  ane56UploadPre: '预单号上传',
  ane56mailNo: '安能运单号查询',
  identify: '安能运单号识别乡镇',
  identifyGoods: '识别商品',
  dept: '部门查询',
  scanning: '包裹扫描',
  scanningBack: '包裹扫描回退',
  scanningList: '扫描列表',
  waitingPanel: '待发货面板',
  msg: '短信查询',
  entranceSigned: '门卫签收',
  entranceList: '门卫扫描管理',
}


export function routeNameToCn (routeName) {
  return routeNameKeyMap[routeName] || '首页'
}
